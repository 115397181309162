@include foundation-typography-base;
//@include foundation-typography-helpers;
//@include foundation-print-styles;

h2 {
  font-weight: $global-weight-bold;
  margin-bottom: rem-calc(20);
  text-transform: uppercase;
}

.subtitle {
  font-size: rem-calc(30);
  font-weight: $global-weight-light;
  text-align: center;
  text-transform: none;
}
