@include foundation-form-text;
@include foundation-form-checkbox;
@include foundation-form-label;
@include foundation-form-error;
@include foundation-form-select;

.form-container {
  background-color: #B19B6D;
}

.form {
  $c:&;
  //@include flex-grid-row(nest);
  position: relative;

  // FORM COLUMN
  &__column {
    @include flex-grid-column(1 of 1);

    @include breakpoint(medium) {
      @include flex-grid-column;
    }
  }

  // FORM SECTION
  &__section {
    @include flex-grid-column(1 of 1);
  }

  // FORM CHECKBOX
  &__checkbox {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-bottom: rem-calc($form-spacing);
    position: relative;

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;

      &:checked + label:after {
        background: $white url('../svg/checkbox.svg') center no-repeat;
        background-size: contain;
      }

      &:focus + label:before {
        outline: rgb(59, 153, 252) auto rem-calc(5);
      }
    }

    label {
      margin: 0;
      padding-left: rem-calc(30);
      position: relative;
      user-select: none;

      &:before,
      &:after {
        content: '';
        position: absolute;
        display: inline-block;
        left: 0;
        top: rem-calc(2);
        height: rem-calc(20);
        width: rem-calc(20);

        border: rem-calc(1) solid #cacaca;
      }
    }

    & + #{$c}__error {
      text-align: center;
    }
  }

  // FORM SUBMIT
  &__submit {
    text-align: center;

    button {
      margin-bottom: 0;
    }
  }

  // FORM ERROR
  &__error {
    @include form-error;
    list-style-type: none;
    margin-left: 0;
  }

  // FORM LOADER
  &__loader {
    display: none;
    font-size: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: rem-calc(-39);
    margin-top: rem-calc(-78);
    text-indent: -9999em;
    z-index: 2;

    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid get-color(primary);

    transform: translateZ(0);
    animation: load8 1.1s infinite linear;

    &,
    &:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
  }

  // FORM IS LOADING
  &--is-loading {
    #{$c}__loader {
      display: block;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #fff;
      opacity: .8;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }

  // FORM FOOTER
  &__footer {
    font-size: rem-calc(11);
    margin-top: rem-calc(20);

    ul {
      line-height: rem-calc(16);
      margin-bottom: 0;
    }

    a {
      border-bottom: rem-calc(1) solid get-color(tertiary);
      transition: color .3s ease-in-out;

      @include on-event {
        color: get-color(tertiary);
      }
    }
  }
}
