.image-zoom {
  overflow: hidden;

  img {
    transition: all .5s;
  }

  a:hover img,
  a:focus img {
    transform: scale(1.1);
  }
}
