$z-index: (
  cookies: 30,
  skip-link: 20,
  header: 40,
  more: 5,
  content: 0
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}
