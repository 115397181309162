.section {
  @include flex-grid-row;
  padding-bottom: rem-calc(30);
  padding-top: rem-calc(30);

  @include breakpoint(medium) {
    padding-bottom: rem-calc(70);
    padding-top: rem-calc(70);
  }

  // SECTION CENTERED
  &--centered {
    @include flex-align($y:middle);
  }

  // SECTION TITLE
  &__title {
    background-color: #333;
    color: $white;
    font-size: rem-calc(25);
    font-weight: 300;
    margin-bottom: 0;
    padding: rem-calc(30 10);
    text-align: center;

    @include breakpoint(medium) {
      font-size: rem-calc(35);
      padding-bottom: rem-calc(55);
      padding-top: rem-calc(55);
    }
  }

  // SECTION INNER
  &__inner {
    @include flex-grid-column(1 of 1);
  }

  // SECTION COLUMN
  &__column {
    @include flex-grid-column(1 of 1);

    @include breakpoint(medium) {
      @include flex-grid-column(50%);
    }
  }
}
