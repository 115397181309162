@include foundation-button;
//@include foundation-button-group;

.button {
  @include button;
  line-height: rem-calc(17);
  text-transform: uppercase;

  // BUTTON DOWNLOAD
  &--download {
    @include button-hollow;
    @include button-hollow-style(#BBBBBB);
    background-image: url('../img/icon-download.png');
    background-position: 8% center;
    background-repeat: no-repeat;
    color: $body-font-color;
    padding-right: rem-calc(30);

    @include breakpoint(small only) {
      @include button-expand;
    }
  }
}
