.landing {
  .main__inner {
    @include breakpoint(medium) {
      padding-top: rem-calc(60);
    }
  }

  // LANDING HEADER
  &__header-info {
    align-items: center;
    background-color: #93E03F;
    display: flex;
    color: $black;
    font-size: rem-calc(15);
    line-height: rem-calc(24);
    margin: rem-calc(0 0 0 18);
    padding: rem-calc(10);
    transition: background-color 250ms ease-in-out;

    @include breakpoint(medium) {
      font-size: rem-calc(20);
    }

    @include on-event {
      background-color: darken(#93E03F, 10%);
    }

    svg {
      @include hide-for-only(small);
      margin-right: rem-calc(10);
    }

    span {
      display: block;
    }
  }

  // LANDING HEADER LANG
  &__header-lang {
    @include breakpoint(small only) {
      display: flex;
    }

    a {
      color: $black;
      padding-bottom: rem-calc(14);
      padding-top: rem-calc(14);
    }
  }

  // LANDING TITLE
  &__title {
    @include flex-grid-column($gutters:rem-calc(40));
    font-size: rem-calc(20);
    font-weight: normal;
    line-height: rem-calc(24);
    margin-bottom: rem-calc(50);
    text-align: center;
    text-transform: none;

    @include breakpoint(medium) {
      @include flex-grid-column;
    }

    span {
      font-weight: $global-weight-bold;
    }

    &:not(:first-child) {
      margin-top: rem-calc(30);

      @include breakpoint(medium) {
        margin-top: rem-calc(60);
      }
    }
  }

  // LANDING SECTION
  &__section {
    background-color: #B19B6D;
    padding: rem-calc(50 30);
    position: relative;

    @include breakpoint(small only) {
      padding: rem-calc(30);
    }

    &:before {
      content: '';
      background-color: #B19B6D;
      display: inline-block;
      height: rem-calc(32);
      margin-left: rem-calc(-16);
      position: absolute;
      left: 50%;
      top: rem-calc(-14);
      transform: rotate(45deg);
      width: rem-calc(32);
    }

    &-title {
      font-weight: 600;
      font-size: 20px;
      line-height: rem-calc(28);
      margin-bottom: 0;
    }

    &-subtitle {
      margin-bottom: rem-calc(20);
    }

    &-title,
    &-subtitle {
      color: #181317;
      text-align: center;
    }

    @include breakpoint(small only) {
      margin-top: rem-calc(40);
    }
  }

  // LANDING FORM
  &__form {
    &:before {
      background: #b19b6d;
    }

    #{text-inputs()},
    select {
      &:not(.is-invalid-input) {
        border: 1px solid #181617;
      }
    }

    .form__checkbox label:after,
    .form__checkbox label:before {
      border: 1px solid #181617;
    }
  }

  // LANDING MESSGE
  &__message {
    color: #181317;
    text-align: center;

    &-title {
      font-size: rem-calc(20);
      font-weight: 600;
      line-height: rem-calc(28);
    }

    &-subtitle {
      line-height: rem-calc(22);
    }

    &-title,
    &-subtitle {
      margin-bottom: 0;
    }
  }

  // LANDING SLIDER
  &__slider {
    background-color: #eee;
    display: block;
    height: rem-calc(300);
    min-height: rem-calc(300);
    overflow: hidden;

    img {
      height: rem-calc(300);
      object-fit: cover;
    }
  }
}

.sticky .landing__form .columns {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  width: 100% !important;
}

#column .card-container:last-child {
  @include breakpoint(small only) {
    margin-bottom: 0;
  }
}
