@include foundation-accordion;

.accordion {
  background-color: $white !important;
  margin-bottom: rem-calc(30) !important;

  &__title {
    text-transform: uppercase;
  }

  &__content {
    background-color: $white !important;
  }

  &__button {
    display: inline-block !important;
    width: auto !important;
  }
}
