.page {
  &__subtitle,
  &__excerpt {
    text-align: center;
  }

  &__excerpt {
    margin-bottom: rem-calc(40);
  }

  &__content .responsive-embed {
    @include responsive-embed('widescreen');
    padding-bottom: ratio-to-percentage(16 by 9);
  }
}
