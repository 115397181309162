.footer {
	border-top: rem-calc(2) solid #F9F9F9;
	font-size: rem-calc(13);

	a {
		font-weight: $global-weight-normal;

		@include on-event {
			color: darken($body-font-color, 15%);
		}
	}

	// FOOTER INNER
	&__inner {
		@include flex-grid-row;
		@include flex-align($y:middle);

		padding-bottom: rem-calc(30);
		padding-top: rem-calc(30);

		@include breakpoint(medium) {
			padding-bottom: rem-calc(70);
			padding-top: rem-calc(70);
		}
	}

	// FOOTER LEFT
	&__left {
		@include flex-grid-column();
	}

	// FOOTER LOGO
	&__logo {
		@include flex-grid-column(100%, rem-calc(40));
		text-align: center;

		@include breakpoint(medium) {
			@include flex-grid-column(100%);
		}

		@include breakpoint(large) {
			@include flex-grid-column(6 of 12);
			display: flex;
			align-items: center;
		}
	}

	// FOOTER RIGHT
	&__right {
		@include flex-grid-column(100%, rem-calc(30));

		@include breakpoint(medium) {
			@include flex-grid-column(100%);
		}

		@include breakpoint(large) {
			@include flex-grid-column(6 of 12);
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	// FOOTER NAV
	&__nav {
		.menu {
			justify-content: center;

			@include breakpoint(large) {
				justify-content: flex-end;
			}

			a {
				@include on-event {
					border-bottom-color: get-color(tertiary);
				}
			}
		}

		li + li {
			display: flex;
			align-items: center;

			&:before {
				content: '';
				position: relative;
				top: rem-calc(-4);
				background-color: $body-font-color;
				display: flex;
				align-items: center;
				height: rem-calc(10);
				margin: rem-calc(0 3);
				width: rem-calc(1);

				@include breakpoint(medium) {
					margin: rem-calc(0 10);
				}
			}
		}
	}

	// FOOTER COPY
	&__copy {
		margin-bottom: 0;

		@include breakpoint(large) {
			margin-left: rem-calc(20);
		}
	}

	// FOOTER SOCIAL
	&__social {
		ul {
			justify-content: center;
		}

		a {
			border-bottom: rem-calc(3) solid transparent;
			margin: rem-calc(0 4);
			padding: rem-calc(0 0 5 0);
			transition: border-bottom-color 300ms ease-in-out;

			@include on-event {
				border-bottom-color: get-color(tertiary);
			}
		}
	}
}
