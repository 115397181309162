.counters {
  list-style-type: none;
  margin: 0;
  @include flex-grid-row;

  li {
    @include flex-grid-column(50%, 0);
    color: get-color(tertiary);
    padding: rem-calc(20);
    position: relative;
    text-align: center;
    text-transform: uppercase;
    word-break: break-word;

    @include breakpoint(medium) {
      padding: rem-calc(30);
    }

    span {
      color: #666;
      display: block;
      font-size: rem-calc(35);
      line-height: rem-calc(38);
    }

    &:nth-child(odd) {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #ddd;
        height: 100%;
        width: 1px;
      }
    }

    &:nth-child(-n+2) {
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        background-color: #ddd;
        height: 1px;
        width: 90%;
      }
    }

    &:nth-child(1) {
      &:before {
        left: 0;
      }
    }
    &:nth-child(2) {
      &:before {
        right: 0;
      }
    }
  }
}
