@include foundation-tabs;

.tabs {
  display: flex;

  &:before,
  &:after {
    content: none;
  }

  @include breakpoint(small only) {
    flex-wrap: wrap;
  }

  @include breakpoint(medium) {
    border-bottom: 2px solid $medium-gray;
  }
}

.tabs-title {
  border-bottom: rem-calc(3) solid transparent;
  flex-grow: 1;
  float: none;
  text-align: center;
  transition: border-bottom-color 300ms ease-in-out;

  @include breakpoint(small only) {
    width: 100%;
  }

  @include breakpoint(medium) {
    position: relative;
    top: 1px;
  }

  & + & {
    @include breakpoint(small only) {
      margin-top: rem-calc(20);
    }
  }

  li {

  }

  &.is-active {
    border-bottom-color: get-color(tertiary);
  }

  a {
    line-height: rem-calc(18);

    @include breakpoint(small only) {
      padding-left: rem-calc(10);
      padding-right: rem-calc(10);
    }
  }
}

.tabs-content {
  margin-bottom: rem-calc(40);
}
