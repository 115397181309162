.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  // PAGINATION ITEM
  &__item {
    @include button;
    @include button-hollow;
    @include button-hollow-style(#BBBBBB);
    margin-bottom: rem-calc(20);
    padding: rem-calc(15);

    @include breakpoint(medium) {
      padding: rem-calc(15 30);
    }

    & + & {
      margin-left: rem-calc(20);
    }

    // ACTIVE
    &-active {
      background-color: #5e5e5e;
      border-color: #5e5e5e;
      color: $white;

      @include on-event {
        background-color: #5e5e5e;
        border-color: #5e5e5e;
        color: $white;
      }
    }

    // DISABLED
    &-disabled {
      cursor: not-allowed;
      opacity: .4;
    }
  }
}
