.logo {
  margin-bottom: 0;

  a {
    svg {
      height: rem-calc(43);
      width: rem-calc(66);

      @include breakpoint(medium) {
        height: rem-calc(58);
        width: rem-calc(90);
      }

      @include breakpoint(large) {
        height: rem-calc(68);
        width: rem-calc(105);
      }
    }
  }

  &__link {
    @include hide-text;
    display: block;
  }
}
