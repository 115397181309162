.featured {
  $c: &;
  @include flex-grid-row;

  // FEATURED ITEM
  &__item {
    @include flex-grid-column(1 of 1);
    margin-bottom: rem-calc(30);

    @include breakpoint(medium) {
      @include flex-grid-column(1 of 2);
    }

    a {
      display: block;

      @include on-event() {
        #{$c}__image {
          &:before {
            opacity: 1;
          }

          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  // FEATURED IMAGE
  &__image {
    overflow: hidden;
    position: relative;

    &:before {
      content: '';
      background: transparent url('../svg/more.svg') center no-repeat;
      position: absolute;
      opacity: 0;
      height: 100%;
      width: 100%;
      transition: opacity 300ms ease-in-out;
      z-index: 1;

      @include breakpoint(small only) {
        background-size: 25%;
      }
    }

    img {
      min-width: 100%;
      transform: scale(1.1);
      transition: all .5s;
    }
  }

  // FEATURED LABEL
  &__label {
    position: absolute;
    top: rem-calc(17);
    right: rem-calc(10);
    color: $white;
    line-height: rem-calc(21);
    padding: rem-calc(8 20);
    text-transform: uppercase;
    z-index: 1;

    &--secondary {
      top: rem-calc(65);
    }
  }

  // FEATURED CONTENT
  &__content {
    color: $body-font-color;
    padding-top: rem-calc(30);
  }

  // FEATURED TITLE
  &__title {
    font-size: rem-calc(16);
    font-weight: $global-weight-medium;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  // FEATURED INFO
  &__info {
    font-size: rem-calc(15);
  }

  // FEATURED MORE
  &__more {
    margin-bottom: 0;
  }
}
