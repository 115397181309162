.hero {
  $c:&;
  background-color: #bbb;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;

  @include breakpoint(medium) {
    height: rem-calc(260);
  }

  // HERO INNER
  &__inner {
    @include flex-grid-row;
    @include flex-grid-column;
    align-items: center;
    flex-direction: column;
    padding-bottom: rem-calc(20);
    padding-top: rem-calc(20);
    z-index: 1;
  }

  // HERO TITLE
  &__title {
    color: $white;
    font-size: rem-calc(35);
    font-weight: $global-weight-light;
    line-height: rem-calc(38);
    margin: 0;
    text-align: center;

    @include breakpoint(medium) {
      font-size: rem-calc(45);
      font-weight: $global-weight-light;
      line-height: rem-calc(47);
    }
  }

  // HERO TAGS
  &__tags {
    margin-bottom: rem-calc(20);
  }

  // HERO LABEL
  &__label {
    color: $white;
    line-height: rem-calc(21);
    padding: rem-calc(8 20);
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(small only) {
      display: block;

      & + & {
        margin-top: rem-calc(10);
      }
    }

    & + & {
      @include breakpoint(medium) {
        margin-left: rem-calc(10);
      }
    }
  }

  // HERO WITH OVERLAY
  &--with-overlay {
    &::before {
      content: '';
      display: block;
      background-color: #f8f8f8;
      opacity: .8;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }

    #{$c}__title {
      color: get-color(primary);
    }
  }
}
