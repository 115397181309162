.slider {
  $c:&;
  display: block !important;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  // SLIDER ITEM
  &__item {
    background-position: center;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    position: relative;
    width: 100%;
    z-index: 3;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    background-color: rgba(0,0,0, 0.7);
    opacity: 0;
    padding: rem-calc(50);
    text-align: center;
    transition: opacity 350ms ease-in-out;

    @include breakpoint(medium) {
      min-width: rem-calc(400);
    }

    @include breakpoint(large) {
      min-width: rem-calc(800);
    }

    #{$c}.owl-loaded & {
      opacity: 1;
    }
  }

  &__title,
  &__subtitle {
    color: $white;
    letter-spacing: 0.27px;
    margin-bottom: 0;
  }

  &__title {
    font-size: rem-calc(25);
    font-weight: $global-weight-bold;
    line-height: rem-calc(30);
    margin-bottom: rem-calc(8);

    @include breakpoint(medium) {
      font-size: rem-calc(35);
      line-height: rem-calc(40);
    }
  }

  &__subtitle {
    font-size: rem-calc(16);
    line-height: rem-calc(20);
    margin-bottom: rem-calc(15);

    @include breakpoint(medium) {
      font-size: rem-calc(35);
      line-height: rem-calc(40);
    }
  }

  &__cta {
    @include button-style(get-color(tertiary), darken(get-color(tertiary), 15%), $black);
    font-weight: $global-weight-medium;
    letter-spacing: 0.27px;
    margin-bottom: 0;
    padding: rem-calc(15 20);
  }

  // SLIDER DOTS
  .owl-dots {
    position: absolute;
    bottom: rem-calc(25);
    width: 100%;

    @include breakpoint(medium) {
      bottom: rem-calc(5);
    }
  }

  // SLIDER LOADING
  &.owl-loading {
    opacity: 1;
  }
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 0;
}
