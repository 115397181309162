.filter {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  margin: 0 auto rem-calc(50);
  padding: rem-calc(0 30);

  @include breakpoint(475px down) {
    max-width: rem-calc(375);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  // FILTER LIST
  &__list {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;

    @include breakpoint(small only) {
      min-width: 170%;
    }

    @include breakpoint(medium) {
      justify-content: center;
    }

    li + li {
      margin-left: rem-calc(20);
    }
  }

  // FILTER ITEM
  &__item {
    @include button;
    @include button-hollow;
    @include button-hollow-style(#BBBBBB);
    margin-bottom: 0;
    padding: rem-calc(15);
    text-transform: uppercase;

    @include breakpoint(medium) {
      padding: rem-calc(15 50);
    }

    @include breakpoint(large) {
      padding: rem-calc(15 75);
    }

    // FILTER ITEM ACTIVE
    &--active {
      background-color: #5e5e5e;
      border-color: #5e5e5e;
      color: $white;

      @include on-event {
        background-color: #5e5e5e;
        border-color: #5e5e5e;
        color: $white;
      }
    }
  }
}
