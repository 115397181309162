.list {
  @include flex-grid-row(nest);
  border-top: rem-calc(2) solid #F9F9F9;
  list-style-type: none;
  margin-bottom: rem-calc(50);
  margin-top: rem-calc(10);
  padding-top: rem-calc(30);

  // LIST ITEM
  &__item {
    @include flex-grid-column(1 of 1);
    margin-bottom: rem-calc(5);

    @include breakpoint(medium) {
      @include flex-grid-column(50%)
    }

    @include breakpoint(large) {
      @include flex-grid-column(1 of 3);
    }

    span {
      font-weight: $global-weight-bold;
      margin-right: rem-calc(5);
      text-transform: uppercase;

      @include breakpoint(small only) {
        display: block;
      }

      &:after {
        content: ':';
        display: inline-block;
      }
    }
  }
}
