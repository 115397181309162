.map {
  width: 100%;
  height: rem-calc(300);

  @include breakpoint(small only) {
    margin-bottom: rem-calc(30);
  }

  @include breakpoint(large) {
    height: rem-calc(340);
  }

  img {
    max-width: inherit !important;
  }
}
