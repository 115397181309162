@include foundation-breadcrumbs;

.breadcrumbs {
  font-weight: $global-weight-light;

  @include breakpoint(small only) {
    li:nth-child(3) {
      max-width: rem-calc(90);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:focus {
        max-width: none;
      }
    }
  }
}
