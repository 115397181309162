:root {
  //--purple: #3B3646;
  //--red: #EE4B5A;
}

$cookies-text-size: rem-calc(11) !default;

.cookies {
  @include z-index(cookies);
  position: fixed;
  right: rem-calc(24);
  bottom: rem-calc(24);
  max-width: rem-calc(430);
  background-color: get-color(secondary);
  padding: rem-calc(20);
  margin-left: rem-calc(24);

  @include breakpoint(medium) {
    bottom: rem-calc(30);

    right: rem-calc(30);
  }

  // COOKIES TITLE
  &__title {
    color: var(--red);
    font-size: rem-calc(18);
    font-weight: $global-weight-bold;
    margin-bottom: rem-calc(10);
  }

  // COOKIES SUBTITLE
  &__subtitle {
    color: $white;
    font-size: $cookies-text-size;
    font-weight: $global-weight-bold;
    margin-bottom: rem-calc(10);
  }

  // COOKIES CONTENT
  &__content {
    p,
    ul {
      color: white;
      font-size: $cookies-text-size;
      line-height: 1.5em;
      margin-bottom: rem-calc(15);
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: 0;
    }

    li {
      display: flex;
      align-items: baseline;
      width: 50%;

      @include breakpoint(small only) {
        width: 100%;
      }
    }

    a {
      color: inherit;
      text-decoration: underline;
    }

    label {
      color: $white;
      font-size: $cookies-text-size;
    }
  }

  // COOKIES BUTTONS
  &__buttons {
    @include breakpoint(medium) {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
    }

    button,
    button#ihavecookiesBtn {
      @include button-base;
      color: $white;
      font-size: $cookies-text-size;
      line-height: rem-calc(18);
      margin-bottom: 0;
      padding: rem-calc(10);
      text-transform: uppercase;

      @include breakpoint(small only) {
        @include button-expand;
        margin-left: 0;
      }

      &:disabled {
        opacity: .3;
      }
    }

    button#gdpr-cookie-accept {
      background-color: rgb(35, 35, 35);
    }

    button#gdpr-cookie-advanced {
      @include button-style(lighten($primary-color, 15%));
      color: $white;
      margin-bottom: 0;

      @include breakpoint(small only) {
        margin-top: rem-calc(15);
      }

      @include breakpoint(medium) {
        margin-left: rem-calc(20);
      }
    }
  }
}

