.box-section {
  @include flex-grid-row;
  @include grid-row-size(expand);

  ::selection {
    background-color: #fff;
    color: get-color(primary);
  }

  // BOX SECTION ITEM
  &__item {
    @include flex-grid-column(100%, 0);

    @include breakpoint(medium) {
      @include flex-grid-column(1 of 2, 0);
    }

    &--flex {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .map {
      margin-bottom: 0;
    }
  }

  // BOX SECTION CONTENT
  &__content {
    background-color: get-color(primary);
    color: #fff;
    font-size: rem-calc(14);
    font-weight: $global-weight-light;
    padding: rem-calc(30);

    @include breakpoint(medium) {
      padding: rem-calc(50);
    }

    @include breakpoint(large) {
      padding: rem-calc(100);
    }

    ul,
    p {
      margin-bottom: 0;
    }

    a {
      color: $white;

      @include on-event {
        color: darken($white, 15%);
      }
    }
  }

  // BOX SECTION IMAGE
  &__image {
    background-position: center bottom;
    background-size: cover;
    min-height: rem-calc(340);
  }
}
