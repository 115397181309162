.banner {
  $c:&;

  // BANNER INNER
  &__inner {
    @include flex-grid-row;
    @include flex-grid-column;
  }

  // BANNER TITLE
  &__title {
    font-size: rem-calc(28);
    font-weight: $global-weight-light;
    line-height: rem-calc(31);
    margin-bottom: 0;
    text-align: center;
  }

  // BANNER CTA
  &__cta {
    @include button;
    line-height: rem-calc(17);
    margin-bottom: 0;
    text-transform: uppercase;
  }

  // BANNER WITH BG
  &--with-bg {
    background-position: center center;
    background-size: cover;
    height: rem-calc(310);

    #{$c}__inner {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: rem-calc(310);
    }

    // BANNER BG TITLE
    #{$c}__title {
      color: $white;
      margin-bottom: rem-calc(10);
    }
  }

  // BANNER WITH BORDER
  &--with-border {
    //margin-top: rem-calc(40);
    //
    //@include breakpoint(medium) {
    //  margin-top: rem-calc(80);
    //}

    #{$c}__inner {
      @include flex-align($y:middle);
      border-top: rem-calc(2) solid #F9F9F9;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-bottom: rem-calc(20);
      padding-top: rem-calc(20);

      @include breakpoint(medium) {
        flex-direction: row;
        justify-content: center;
        padding-bottom: rem-calc(50);
        padding-top: rem-calc(50);
      }
    }

    #{$c}__title {
      @include breakpoint(medium) {
        margin-right: rem-calc(40);
      }
    }

    #{$c}__cta {
      @include breakpoint(small only) {
        @include button-expand;
        margin-top: rem-calc(20);
      }
    }
  }
}
