.social-links {
  @include menu-base;

  @include breakpoint(mediumh) {
    margin-left: rem-calc(18);
  }

  a {
    padding: rem-calc(5);
  }
}
