// Row
.row {
	$c:&;
	@include flex-grid-row;

	// Nesting behavior
	& .row {
	  @include flex-grid-row(nest, $base: false);
	}

	// Expanded row
	&#{$c}--expanded {
	  @include grid-row-size(expand);
	}

	&#{$c}--collapse {
	  > .column {
	    @include grid-col-collapse;
	  }
	}
}

// Column
.column {
	@include flex-grid-column;
}

@include -zf-each-breakpoint {
	@for $i from 1 through $grid-column-count {
		// Sizing (percentage)
		.#{$-zf-size}-#{$i} {
		  flex: flex-grid-column($i);
		  max-width: grid-column($i);
		}

		// Offsets
		$o: $i - 1;

		.#{$-zf-size}-offset-#{$o} {
			@include grid-column-offset($o);
		}
	}
}

.columns {
	@extend .column; // sass-lint:disable-line placeholder-in-extend
}

// Container
.container {
	@include flex-grid-row($base: false);
	@include flex-grid-column;

	// Nesting behavior
	& .row {
		@include flex-grid-row(nest, $base: false);
	}
}
