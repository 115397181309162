.hero-landing {
  $c:&;
  background-color: $white;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  height: 50vh;

  //@include breakpoint(medium) {
  //  height: 50vh;
  //}

  // HERO INNER
  &__inner {
    @include flex-grid-row;
    @include flex-grid-column($gutters:rem-calc(40));
    align-items: center;
    flex-direction: column;
    padding-bottom: rem-calc(20);
    padding-top: rem-calc(80);
    z-index: 1;

    @include breakpoint(medium) {
      @include flex-grid-column;
    }
  }

  // HERO CONTENT
  &__content {
    background-color: rgba(255,255,255,.7);
    padding: rem-calc(20);
    text-align: center;
  }

  // HERO TITLE
  &__title {
    color: #181417;
    font-size: rem-calc(28);
    line-height: rem-calc(34);
    margin: 0;

    @include breakpoint(medium) {
      font-size: rem-calc(32);
      line-height: rem-calc(38);
      margin: 0 auto;
      //max-width: 60%;
    }

    span {
      font-weight: $global-weight-bold;
    }
  }

  // HERO BUTTON
  &__button {
    @include button;
    margin-top: rem-calc($global-margin);
    text-transform: uppercase;
  }
}
