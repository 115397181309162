@include foundation-global-styles;

::selection {
  background-color: get-color(primary);
  color: $white;
}
::-moz-selection {
  background-color: get-color(primary);
  color: $white;
}

html {
  //scroll-behavior: smooth;
}

label {
  a {
    text-decoration: underline;
  }

  &.is-invalid-label a {
    color: $input-error-color;
  }
}

dl dt,
dd {
  display: inline-block;
}

dl {
  border-top: rem-calc(2) solid #F9F9F9;
  margin-top: rem-calc(10) !important;
  padding-top: rem-calc(30) !important;
  text-transform: uppercase;
}

dd {
  text-transform: none;
}

p > a {
  border-bottom: rem-calc(1) solid get-color(tertiary);
  transition: color .3s ease-in-out;

  @include on-event {
    color: get-color(tertiary);
  }
}
