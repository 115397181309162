.main {
  position: relative;

  // MAIN CONTENT
  &__content {
    padding-top: rem-calc(40);

    @include breakpoint(medium) {
      padding-top: rem-calc(80);
    }

    &--big {
      padding-top: rem-calc(89);

      @include breakpoint(medium) {
        padding-top: rem-calc(134);
      }
    }
  }

  // MAIN INNER
  &__inner {
    padding-bottom: rem-calc(30);
    padding-top: rem-calc(30);

    @include breakpoint(medium) {
      padding-bottom: rem-calc(50);
      padding-top: rem-calc(50);
    }

    @include breakpoint(large) {
      padding-bottom: rem-calc(50);
      padding-top: rem-calc(50);
    }

    &--big {
      @include breakpoint(medium) {
        padding-bottom: rem-calc(68);
        padding-top: rem-calc(68);
      }
    }

    &--collapsed-bottom {
      padding-bottom: 0;
    }
  }

  // MAIN CONTAINER
  &__container {
    @include flex-grid-row($base:false);
    @include flex-grid-column;

    &--small {
      @include flex-grid-row($size:rem-calc(880));
      @include flex-grid-column;
    }
  }

  // MAIN SECTION
  &__section {
    padding-bottom: rem-calc(45);
  }

  // MAIN BACK
  &__back {
    margin-bottom: rem-calc(30);

    @include breakpoint(small only) {
      @include button-expand;
    }
  }

  // MAIN SUBTITLE
  &__subtitle {
    a {
      transition: color .3s ease-in-out;

      @include on-event {
        color: get-color(tertiary);
      }
    }
  }
}
