//@include foundation-visibility-classes;
//@include foundation-text-alignment;

// @include foundation-prototype-classes;

.skip-link {
  @include visually-hidden;

  &:focus {
    @include z-index(skip-link);
    background-color: $white;
    border-radius: rem-calc(3);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
    clip: auto !important;
    color: get-color(primary);
    display: block;
    font-size: rem-calc(14);
    font-weight: bold;
    height: auto;
    left: 50%;
    line-height: normal;
    max-width: rem-calc(250);
    padding: rem-calc(15 20);
    text-align: center;
    text-decoration: none;
    top: rem-calc(10);
    transform: translateX(rem-calc(-125));
    width: rem-calc(250);
  }
}

// Screen reader visibility classes
// Need a "hide-for-sr" class? Add aria-hidden='true' to the element
.show-for-sr,
.show-on-focus {
  @include element-invisible;
}

.text-bold {
  font-weight: $global-weight-bold;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.thumb {
  @include breakpoint(medium down) {
    margin-bottom: rem-calc(30);
    min-width: 100%;
  }
}

.lazy {
  filter: blur(8px);
  transition: filter 0.3s;
}

.lozad {
  //filter: blur(8px);
  //opacity: 0;
  min-width: 100%;
}
.lozad.loaded {
  //opacity: 1;
  //transition: opacity 300ms;
  //transition: filter 0.3s;
}
