//@include foundation-menu;
//@include foundation-menu-icon;

.menu {
  @include menu-base;

  &, &--horizontal {
    @include menu-direction(horizontal);
  }

  a {
    border-bottom: rem-calc(3) solid transparent;
    color: #444;
    font-weight: 300;
    margin: rem-calc(0 4);
    padding: rem-calc(0 0 5 0);
    transition: border-bottom-color 300ms ease-in-out;

    @include on-event {
      border-bottom-color: #000;
    }
  }

  // ACTIVE
  .is-active a {
    border-bottom-color: #000;
  }

  // MENU VERTICAL
  &--vertical {
    @include menu-direction(vertical);
    align-items: center;

    li + li {
      margin-top: rem-calc(20);
    }
  }
}
