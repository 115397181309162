.card-container {
  text-align: right;

  @include breakpoint(small only) {
    margin-bottom: 20%;
  }

  & + & {
    margin-top: 20%;
  }
}

.card {
  $c:&;
  @include card-container;
  overflow: visible;
  position: relative;

  // CARD BG
  &__bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: rem-calc(30);

    @include breakpoint(medium) {
      min-height: rem-calc(330);
    }

    @include breakpoint(large) {
      min-height: rem-calc(438);
    }

    #{$c}__title {
      color: #B29A69;
      font-size: rem-calc(20);
      text-align: center;
      text-transform: none;
    }

    #{$c}__subtitle {
      font-size: rem-calc(20);
    }
  }

  // CARD HEADER
  &__header {
    background-color: #181617;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: rem-calc(10 20);
    position: absolute;
    left: 0;
    top: rem-calc(-22);
    z-index: 2;

    @include breakpoint(medium) {
      height: rem-calc(45);
      min-width: 50%;
    }
  }

  // CARD TITLE
  &__title {
    color: $white;
    font-weight: $global-weight-normal;
    margin-bottom: 0;
    text-align: left;
    text-transform: initial;

    @include breakpoint(small only) {
      font-size: rem-calc(16);
    }
  }

  // CARD SUBTITLE
  &__subtitle {
    color: $white;
    margin-bottom: 0;
    text-align: center;
  }

  // CARD CONTENT
  &__content {
    & > *:nth-child(2) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  // CARD SECTION
  &__section {
    @include card-section;
    background-color: #eee;
    padding: rem-calc(15);
    text-align: left;

    @include breakpoint(large) {
      padding: rem-calc(30 40);
    }

    hr {
      margin: 0;
    }
  }

  // CARD DIVIDER
  &__divider {
    @include card-divider;
  }

  // CARD IMAGE
  // For IE 11 - Flexbug
  // https://github.com/philipwalton/flexbugs/issues/75
  &__image {
    min-height: 1px;

    img {
      width: 100%;
    }
  }

  // CARD SLIDER
  &__slider {
    img {
      color: #eee;
    }
  }

  // CARD FOOTER
  &__footer {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;

    @include breakpoint(small only) {
      flex-direction: column;
      align-items: flex-start;
    }

    p {
      margin-bottom: 0;

      span {
        display: block;
        font-size: rem-calc(14);
        text-transform: uppercase;
      }

      &:first-child {
        @include breakpoint(small only) {
          margin-bottom: rem-calc(10);
        }

        @include breakpoint(medium) {
          margin-right: rem-calc(20);
        }
      }

      &:last-child {
        @include breakpoint(small only) {
          margin-top: rem-calc(20);
        }

        @include breakpoint(medium) {
          flex-grow: 1;
          margin-left: rem-calc(30);
          text-align: right;
        }
      }
    }
  }

  // CARD CTA
  &__cta {
    @include button($background:get-color(tertiary),$background-hover:darken(get-color(tertiary), 15%),$color:#181617);
    margin-bottom: 0;
    margin-top: rem-calc(15);
    text-transform: uppercase;

    @include breakpoint(small only) {
      @include button-expand;
    }
  }

  // CARD EXTRA
  &__extra {
    background-color: #eee;
    font-size: rem-calc(14);
    text-align: left;
    padding: rem-calc(15);
    text-transform: uppercase;

    @include breakpoint(large) {
      padding: rem-calc(30 40);
    }
  }

  // CARD EXPANDABLE
  &__expandable {
    @include accordion-container;
    background-color: inherit;
    margin: 0;

    &-item {
      a {
        display: block;
        line-height: 1;
        margin-bottom: rem-calc(8);
        position: relative;

        &:before {
          font-size: rem-calc(24);
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: rem-calc(-12);
          content: "+";
        }
      }

      &.is-active a:before {
        content: '–';
      }
    }

    &-content {
      @include accordion-content;
      background-color: #eee;
      border: none !important;
      padding: 0;
    }

    &-button {
      border: rem-calc(1) solid #bbb;
      cursor: pointer;
      display: block;
      margin: rem-calc(0 0 5);
      padding: rem-calc(10);
      width: 100%;

      @include on-event {
        background-color: get-color(primary);
        border-color: get-color(primary);
        transition: background-color 250ms ease-in-out, border 250ms ease-in-out, color 250ms ease-in-out;
        color: $white;
      }
    }
  }
}
