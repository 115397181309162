// INTRO
.intro {
  max-height: 100vh;
  position: relative;

  // HIDE LOADER
  &--hide-loader:before {
    display: none;
  }

  &:before {
    content: '';
    font-size: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: rem-calc(-39);
    margin-top: rem-calc(-78);
    text-indent: -9999em;
    z-index: 2;

    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid get-color(primary);

    transform: translateZ(0);
    animation: load8 1.1s infinite linear;

    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
}

// MORE
.more {
  @include z-index(more);
  @include button;
  bottom: rem-calc(85);
  margin-bottom: 0;
  position: absolute;
  left: 50%;
  margin-left: rem-calc(-125);
  text-transform: uppercase;
  width: rem-calc(250);

  @include breakpoint(medium) {
    bottom: rem-calc(45);
  }
}

.is-page-home {
  .header__inner {
    @include breakpoint(medium) {
      padding-bottom: rem-calc(50);
      padding-top: rem-calc(50);
    }
  }
}
