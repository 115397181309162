@include foundation-reveal;

.reveal {
  animation-duration: 1s;
  animation-fill-mode: both;

  // REVEAL CONTENT
  &__content {
    font-size: rem-calc(14);

    h1 {
      font-size: rem-calc(30);
      font-weight: $global-weight-light;
      text-align: center;
      text-transform: none;
    }
  }

  &__button {
    @include button;
    @include button-expand;
    margin-bottom: 0;
    text-transform: uppercase;
  }
}
