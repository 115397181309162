.article {
  $c: &;
  margin-bottom: rem-calc(30);

  a {
    @include on-event() {
      #{$c}__image {
        &:before {
          opacity: 1;
        }

        img {
          transform: scale(1.2);
        }
      }
    }
  }

  // ARTICLE IMAGE
  &__image {
    margin-bottom: rem-calc(30);
    overflow: hidden;
    position: relative;

    &:before {
      content: '';
      background: transparent url('../svg/more.svg') center no-repeat;
      position: absolute;
      opacity: 0;
      height: 100%;
      width: 100%;
      transition: opacity 300ms ease-in-out;
      z-index: 1;

      @include breakpoint(small only) {
        background-size: 25%;
      }
    }

    img {
      min-width: 100%;
      transform: scale(1.1);
      transition: all .5s;
    }
  }

  // ARTICLE TITLE
  &__title {
    font-size: rem-calc(20);
    font-weight: $global-weight-medium;
    line-height: rem-calc(25);
    margin-bottom: 0;

    &--big {
      font-size: rem-calc(45);
      font-weight: $global-weight-light;
      line-height: rem-calc(47);
      margin-bottom: rem-calc(20);
    }
  }

  // ARTICLE SUBTITLE
  &__subtitle {
    font-size: rem-calc(16);
    line-height: rem-calc(21);

    &--big {
      font-size: rem-calc(30);
      line-height: rem-calc(32);
    }
  }

  // ARTICLE DATE
  &__date {
    color: get-color(tertiary);
  }

  // ARTICLE FOOTER
  &__footer {
    text-align: right;
  }

  // ARTICLE MORE
  &__more {
    @include button;
    margin-bottom: 0;
    text-transform: uppercase;

    @include breakpoint(small only) {
      @include button-expand;
    }
  }
}
