.header {
	$c: &;
	@include z-index(header);
	background-color: rgba(255, 255, 255, .8);
	position: fixed;
	transition: background-color 350ms ease-in-out;
	width: 100%;

	// HEADER INNER
	&__inner {
		@include flex-grid-row;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;
		padding: rem-calc(20);

		@include breakpoint(medium) {
			@include flex-grid-column;
			padding-bottom: rem-calc(30);
			padding-top: rem-calc(30);
		}
	}

	// HEADER LOGO
	&__logo {
		margin-right: rem-calc(20);

		@include breakpoint(medium only) {
			margin-right: rem-calc(14);
		}

		a {
			svg {
				height: rem-calc(43);
				width: rem-calc(66);

				@include breakpoint(medium) {
					height: rem-calc(58);
					width: rem-calc(90);
				}

				@include breakpoint(667px) {
					height: rem-calc(53);
				}

				@include breakpoint(large) {
					height: rem-calc(68);
					width: rem-calc(105);
				}
			}
		}
	}

	// HEADER RIGHT
	&__right {
		display: flex;
		align-items: center;
	}

	// HEADER NAV
	&__nav {
		display: none;

		@include breakpoint(medium) {
			margin-right: rem-calc(22);
			margin-top: rem-calc(10);
		}

		@include breakpoint(mediuml up) {
			display: block;
		}

		@include breakpoint(large) {
			margin-right: rem-calc(40);
		}

		li + li {
			margin-left: rem-calc(30);

			@include breakpoint(667px down) {
				margin-left: rem-calc(13);
			}

			@include breakpoint(medium only) {
				margin-left: rem-calc(7);
			}
		}

		a {
			border-bottom: rem-calc(3) solid transparent;
			color: #444;
			font-weight: 300;
			margin: rem-calc(0 4);
			padding: rem-calc(0 0 5 0);
			transition: border-bottom-color 300ms ease-in-out;

			@include on-event {
				border-bottom-color: get-color(tertiary);
			}
		}

		// ACTIVE
		.is-active a {
			border-bottom-color: #000;
		}
	}

	// HEADER SOCIAL
	&__social {
		display: none;

		@include breakpoint(mediumh) {
			display: block;
		}
	}

	// HEADER INFO
	&__info {
		font-size: rem-calc(13);
		font-weight: 500;

		@include breakpoint(mediumh) {
			font-size: rem-calc(15);
			margin-left: rem-calc(18);
			margin-right: 0;
		}

		@include breakpoint(large) {
			//margin-left: rem-calc(50);
		}

		a {
			color: $body-font-color;

			@include on-event {
				color: darken($body-font-color, 15%);
			}
		}
	}

	// HEADER TRIGGER
	&__trigger {
		height: rem-calc(38);
		z-index: 11;

		@include breakpoint(mediumh down) {
			margin-left: rem-calc(40);
		}

		@include breakpoint(mediuml up) {
			display: none;
		}
	}

	// HEADER COLLAPSIBLE
	&__collapsible {
		@include dropdown-container;
		left: 0 !important;
		margin: rem-calc(15);
		padding: rem-calc(80 15 20);
		top: 0 !important;
		width: 92%;

		@include breakpoint(667px) {
			width: 96%;
		}

		@include breakpoint(mediuml up) {
			display: none !important;
		}

		// HEADER COLLAPSIBLE BOTTOM
		& &-bottom {
			display: flex;
			align-items: center;
			justify-content: center;

			& > * + * {
				margin-left: rem-calc(10);
			}
		}

		nav {
			border-bottom: rem-calc(2) solid $white;
			margin-bottom: rem-calc(20);
			padding-bottom: rem-calc(30);
		}

		a {
			text-align: center;
		}

		#{$c}__social {
			display: block;
		}

		#{$c}__lang {
			display: flex !important;
			font-size: rem-calc(16);
			//padding-top: rem-calc(20);
		}
	}

	// HEADER LANG
	&__lang {
		@include menu-base;
		display: none;
		flex-wrap: nowrap;
		font-size: rem-calc(14);
		justify-content: center;
		text-transform: uppercase;

		@include breakpoint(mediumh up) {
			display: flex;
		}

		li + li {
			margin-left: rem-calc(10);
		}

		a {
			border: rem-calc(1) solid #BBB;
			color: #717171;

			@include breakpoint(small only) {
				padding: rem-calc(10);
			}
		}

		.is-active a {
			background-color: #262626;
			border-color: #262626;
			color: $white;
		}
	}
}
