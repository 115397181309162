.gallery {
  margin-bottom: rem-calc(45);

  // GALLERY INNER
  &__inner {
    @include flex-grid-row;

    @include breakpoint(small only) {
      @include flex-grid-column;
    }
  }

  // GALLERY ITEM
  &__item {
    @include flex-grid-column(100%, 0);
    margin-bottom: rem-calc(20);

    @include breakpoint(medium) {
      @include flex-grid-column(1 of 3);
      margin-bottom: rem-calc(30);
    }

    figcaption {
      display: none;
    }

    // ITEM BIG
    &--big {
      @include flex-grid-column(1 of 1, 0);

      @include breakpoint(medium) {
        @include flex-grid-column(8 of 12);
      }
    }
  }

  a {
    display: block;
    overflow: hidden;
    position: relative;

    // HOVER OVERLAY
    &:before {
      content: '';
      background: transparent url('../svg/more.svg') center no-repeat;
      position: absolute;
      opacity: 0;
      height: 100%;
      width: 100%;
      transition: opacity 300ms ease-in-out;
      z-index: 1;

      @include breakpoint(small only) {
        background-size: rem-calc(60);
      }
    }

    img {
      transform: scale(1.1);
      transition: all .5s;
    }

    @include on-event() {
      &:before {
        opacity: 1;
      }

      img {
        transform: scale(1.2);
      }
    }
  }

  img {
    @include breakpoint(small only) {
      height: rem-calc(158);
      object-fit: cover;
      width: 100%;
    }

    @include breakpoint(medium) {
      height: rem-calc(225);
      object-fit: cover;
      width: 100%;
    }

    @include breakpoint(large) {
      height: rem-calc(330);
      object-fit: cover;
      width: 100%;
    }
  }
}
